import useAxios from 'hooks/useAxios';

export const getUsers = async (limit = 20, page = 1) => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/backoffice-user', {
      params: { limit: limit, page: page }
    });

    return {
      data: unformattedData.data.data,
      meta: unformattedData.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const getUser = async (id) => {
  const api = useAxios();
  try {
    return await api.get(`/backoffice-user/${id}`);
  } catch (err) {
    console.log(err);
    return {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        role: {
          id: '',
          name: ''
        }
      }
    };
  }
};

export const createUser = async (user) => {
  const api = useAxios();
  try {
    console.log('user', user);
    return await api.post('/backoffice-user', {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password,
      role: user.role
    });
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};
export const updateUser = async (id, user) => {
  const api = useAxios();
  try {
    return await api.put(`/backoffice-user/${id}`, user);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};

export const deleteUser = async (id) => {
  const api = useAxios();
  try {
    return await api.delete(`/backoffice-user/${id}`);
  } catch (err) {
    console.log(err);
    return {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        role: {
          id: '',
          name: ''
        }
      }
    };
  }
};
