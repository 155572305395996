const clientColumnData = [
  {
    Header: 'Id',
    accessor: 'id'
  },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Callback URL',
    accessor: 'callbackUrl'
  },
  {
    Header: 'Whitelisted IPs',
    accessor: 'whitelistedIps'
  },
  {
    Header: 'Created At',
    accessor: 'createdAt'
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt'
  }
];
export default clientColumnData;
