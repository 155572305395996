const metricColumnData = [
  {
    Header: 'Metric type',
    accessor: 'metricType'
  },
  {
    Header: 'Amount',
    accessor: 'amount'
  },
  {
    Header: 'Metric date',
    accessor: 'createdAt'
  },
  {
    Header: 'Player Username',
    accessor: 'playerUsername'
  },
  {
    Header: 'Player Nickname',
    accessor: 'playerNickname',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Game',
    accessor: 'game'
  },
  {
    Header: 'Bet range',
    accessor: 'betRange',
    Cell: ({ value }) => {
      return value?.minBet !== null && value?.maxBet !== null
        ? `${value.min} - ${value.max}`
        : value?.singleBet !== null
        ? value.singleBet
        : '-';
    }
  },
  {
    Header: 'Currency',
    accessor: 'currency'
  },
  {
    Header: 'Client',
    accessor: 'client'
  },
  {
    Header: 'Transaction Id',
    accessor: 'transactionId',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  // {
  //   Header: 'Session Date',
  //   accessor: 'sessionDate'
  // },
  {
    Header: 'Country',
    accessor: 'sessionCountry',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Base Amount',
    accessor: 'baseAmount',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Base Rake Amount',
    accessor: 'baseRakeAmount',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Base Currency',
    accessor: 'baseCurrency',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  }
];
export default metricColumnData;
