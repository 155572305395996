/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import NewUser from 'layouts/user-management/new-user';

import SignInBasic from 'layouts/authentication/sign-in/basic';

// Material Dashboard 2 PRO React components

// @mui icons
import Icon from '@mui/material/Icon';

// Images
import UserManagement from 'layouts/user-management';
import Dashboard from 'layouts/dashboard';
import EditUser from 'layouts/user-management/edit-user';
import Logout from 'layouts/authentication/logout';
import ShowUser from 'layouts/user-management/show-user';
import ClientManagement from 'layouts/client-management';
import Games from 'layouts/games';
import Players from 'layouts/players';
import Sessions from 'layouts/sessions';
import Metrics from 'layouts/metrics';
import EditClient from 'layouts/client-management/edit-client';
import Settings from 'layouts/pages/account/settings';
import Game from 'layouts/game';

const routes = [
  { type: 'title', title: 'Home', key: 'title-home' },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize='medium'>dashboard</Icon>,
    route: '/dashboard', //to be changed
    component: <Dashboard />
  },
  { type: 'title', title: 'Sections', key: 'title-sections' },
  {
    type: 'collapse',
    name: 'Admin management',
    action: 'read',
    object: 'admin',
    key: 'admin-management',
    icon: <Icon fontSize='medium'>manageAccounts</Icon>,
    collapse: [
      {
        name: 'User management',
        key: 'user-management',
        action: 'read',
        object: 'user',
        icon: <Icon fontSize='medium'>people</Icon>,
        route: '/user-management',
        component: <UserManagement />
      },
      {
        name: 'Client management',
        key: 'client-management',
        action: 'read',
        object: 'client',
        icon: <Icon fontSize='medium'>person</Icon>,
        route: '/client-management',
        component: <ClientManagement />
      }
    ]
  },
  {
    type: 'collapse',
    noCollapse: true,
    icon: <Icon fontSize='medium'>casino</Icon>,
    name: 'Games',
    key: 'games',
    route: '/games',
    component: <Games />
  },
  {
    type: 'collapse',
    noCollapse: true,
    icon: <Icon fontSize='medium'>assessment</Icon>,
    name: 'Sessions',
    key: 'sessions',
    route: '/sessions',
    component: <Sessions />
  },
  {
    type: 'collapse',
    noCollapse: true,
    icon: <Icon fontSize='medium'>analytics</Icon>,
    name: 'Metrics',
    key: 'metrics',
    route: '/metrics',
    component: <Metrics />
  },
  {
    type: 'collapse',
    noCollapse: true,
    icon: <Icon fontSize='medium'>face</Icon>,
    name: 'Players',
    key: 'players',
    route: '/players',
    component: <Players />
  },
  {
    name: 'Sign In',
    key: 'sign-in',
    route: '/authentication/sign-in/basic',
    component: <SignInBasic />
  },
  {
    name: 'New User',
    key: 'new-user',
    route: '/user-management/new-user',
    component: <NewUser />
  },
  {
    name: 'Show User',
    key: 'show-user',
    route: '/user-management/show/:id',
    component: <ShowUser />
  },
  {
    name: 'Edit User',
    key: 'edit-user',
    route: '/user-management/edit/:id',
    component: <EditUser />
  },
  {
    type: 'collapse',
    name: 'Settings',
    key: 'settings',
    icon: <Icon fontSize='medium'>settings</Icon>,
    collapse: [
      {
        name: 'Edit user',
        key: 'edit user',
        icon: <Icon fontSize='medium'>edit</Icon>,
        route: '/settings/user',
        component: <Settings />
      }
    ]
  },
  {
    type: 'collapse',
    noCollapse: true,
    icon: <Icon fontSize='medium'>logout</Icon>,
    name: 'Logout',
    key: 'logout',
    route: '/authentication/logout',
    component: <Logout />
  },
  {
    name: 'Edit Client',
    key: 'edit-client',
    route: '/client-management/edit/:id',
    component: <EditClient />
  },
  {
    name: 'Game',
    key: 'game',
    route: '/game/:id',
    component: <Game />
  }
];

export default routes;
