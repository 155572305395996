import DataTablePage from 'components/DataTablePage';
import { Navigate } from 'react-router-dom';
import { Can } from 'context';

import { getSessions } from 'services/sessions';
import sessionsColumnData from 'data/sessionsColumnData';

function Sessions() {
  return (
    <>
      <Can I='read' a='session'>
        <DataTablePage
          title='Sessions'
          canSearch
          canFilter
          fetchData={getSessions}
          queryKey='sessions'
          columnData={sessionsColumnData}
          object={'session'}
          noActions={true}
        />
      </Can>
      <Can not I='read' a='session'>
        <Navigate to='/dashboard' replace />
      </Can>
    </>
  );
}

export default Sessions;
