/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react';
// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// NewUser page components
import FormField from 'layouts/user-management/components/FormField';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import MDInput from 'components/MDInput';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
function ClientInfo({ formData }) {
  const { formField, values, errors, touched, setFieldValue, isSubmitting } = formData;
  const { name, callbackUrl, whitelistedIps } = formField;
  const { name: nameV, callbackUrl: callbackUrlV, whitelistedIps: whitelistedIpsV } = values;
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  useEffect(() => {
    if (!isSubmitting) {
      setRoleName('');
    }
  }, [isSubmitting]);

  const [roleName, setRoleName] = useState('');
  const [open, setOpen] = useState(false);
  const [ipOption, setIpOption] = useState([]);
  const [whitelistedIpsNames, setWhitelistedIpsNames] = useState(whitelistedIpsV);
  console.log(whitelistedIpsV);
  const whitelistIpsOptionsChange = (event, value) => {
    setIpOption([value]);
  };

  const setWhitelistedIpsValue = (event, value) => {
    setWhitelistedIpsNames(value);
    setFieldValue(whitelistedIps.name, value);
    setIpOption([]);
  };
  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV && !errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={callbackUrl.type}
              label={callbackUrl.label}
              name={callbackUrl.name}
              value={callbackUrlV}
              placeholder={callbackUrl.placeholder}
              error={errors.callbackUrl && touched.callbackUrl}
              success={callbackUrlV && !errors.callbackUrl}
            />
          </Grid>
        </Grid>
        <Grid item xs={24} sm={12}>
          <MDBox mb={1.5}>
            <Autocomplete
              multiple
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              options={ipOption}
              disableCloseOnSelect
              value={whitelistedIpsNames}
              onChange={setWhitelistedIpsValue}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label='Whitelisted IPs' variant='standard' />}
              onInputChange={whitelistIpsOptionsChange}
            />
            <MDBox mt={0.75}>
              <MDTypography component='div' variant='caption' color='error' fontWeight='regular'>
                <ErrorMessage name={whitelistedIps.name} />
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
            <FormField
              type={whitelistedIps.type}
              label={whitelistedIps.label}
              name={whitelistedIps.name}
              value={whitelistedIpsV}
              placeholder={whitelistedIps.placeholder}
              error={errors.whitelistedIps && touched.whitelistedIps}
              success={whitelistedIpsV && !errors.whitelistedIps}
            />
          </Grid> */}
      </MDBox>
    </MDBox>
  );
}

// typechecking props for ClientInfo
ClientInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default ClientInfo;
