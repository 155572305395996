import dayjs from 'dayjs';
import useAxios from 'hooks/useAxios';

export const getMetrics = async (limit = 20, page = 1, filters = '') => {
  const api = useAxios();
  let dateFrom = filters.startDate || '';
  let dateTo = filters.endDate || '';

  if (dateFrom === '') {
    dateFrom = dayjs().date(1).month(1).year(2000);
    dateFrom = dateFrom.toJSON();
  } else {
    dateFrom = new Date(dateFrom).toISOString();
  }

  if (dateTo === '') {
    dateTo = dayjs();
    dateTo = dateTo.toJSON();
  } else {
    dateTo = new Date(dateTo).toISOString();
  }
  try {
    const unformattedData = await api.get('/metric', {
      params: {
        limit: limit,
        page: page,
        search: filters?.search,
        startDate: dateFrom,
        endDate: dateTo
      }
    });
    return {
      data: unformattedData.data.data.map((x) => {
        return {
          ...x,
          playerUsername: x.session.player.username,
          game: x.session.game.name,
          betRange: x.session.betLimit,
          currency: x.session.player.currency,
          client: x.session.player.client.name,
          sessionCountry: x.session?.country,
          playerNickname: x.session?.player?.nickname
        };
      }),
      meta: unformattedData.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};
