import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useEffect, useState } from 'react';
import { deleteBetLimit, updateBetLimit, updateMinMaxBetLimit, updateSingleBetLimit } from 'services/games';

export default function BetLimit({ id, minBet, maxBet, gameId, singleBet }) {
  const [editBetLimitMin, setEditBetLimitMin] = useState(minBet);
  const [editBetLimitMax, setEditBetLimitMax] = useState(maxBet);
  const [editBetLimitSingle, setEditBetLimitSingle] = useState(singleBet);

  const [openDeleteBetLimit, setOpenDeleteBetLimit] = useState();
  const handleOpenDeleteBetLimit = () => setOpenDeleteBetLimit(true);
  const handleCloseDeleteBetLimit = () => setOpenDeleteBetLimit(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setUpdated(false);
    }, 5000);
  }, [updated]);

  return (
    <>
      <MDBox
        sx={{
          border: '0.5px solid #fff',
          borderRadius: '8px',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '23%',
          background: '#152141'
        }}
      >
        {minBet && (
          <MDBox
            sx={{
              color: '#fff',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Min Bet</label>
            <input
              type='number'
              value={editBetLimitMin}
              onChange={(e) => {
                setEditBetLimitMin(e.target.value);
              }}
              style={{
                background: '#FFF',
                border: '2px solid #333333',
                color: '#000',
                borderRadius: '8px',
                padding: '10px 15px',
                width: '60%'
              }}
            ></input>
          </MDBox>
        )}
        {maxBet && (
          <MDBox
            sx={{
              color: '#fff',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Max Bet</label>
            <input
              type='number'
              value={editBetLimitMax}
              onChange={(e) => {
                setEditBetLimitMax(e.target.value);
              }}
              style={{
                background: '#FFF',
                border: '2px solid #333333',
                color: '#000',
                borderRadius: '8px',
                padding: '10px 15px',
                width: '60%'
              }}
            ></input>
          </MDBox>
        )}
        {singleBet && (
          <MDBox
            sx={{
              color: '#fff',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Single Bet</label>
            <input
              type='number'
              value={editBetLimitSingle}
              onChange={(e) => {
                setEditBetLimitSingle(e.target.value);
              }}
              style={{
                background: '#FFF',
                border: '2px solid #333333',
                color: '#000',
                borderRadius: '8px',
                padding: '10px 15px',
                width: '60%'
              }}
            ></input>
          </MDBox>
        )}
        {errorMessage && (
          <MDBox sx={{ fontSize: '12px', textAlign: 'center', color: '#e71616' }}>
            Max Bet must be greater than Min Bet.
          </MDBox>
        )}
        {updated && (
          <MDBox sx={{ fontSize: '12px', textAlign: 'center', color: '#fff' }}>Bet limit updated successfully.</MDBox>
        )}
        <MDBox sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <MDButton
            color={'info'}
            sx={{ width: '48%', alignSelf: 'center' }}
            onClick={() => {
              if (Number(editBetLimitMin) >= Number(editBetLimitMax) && singleBet === null) {
                setErrorMessage(true);
              } else {
                setErrorMessage(false);
                singleBet === null
                  ? updateMinMaxBetLimit(id, gameId, editBetLimitMin, editBetLimitMax).then((res) =>
                      setUpdated(res.status === 200)
                    )
                  : updateSingleBetLimit(id, gameId, editBetLimitSingle).then((res) =>
                      setUpdated(res.status === 200)
                    );
              }
            }}
          >
            Update
          </MDButton>
          <MDButton
            sx={{ width: '48%', alignSelf: 'center' }}
            onClick={() => {
              handleOpenDeleteBetLimit();
            }}
          >
            Delete
          </MDButton>
        </MDBox>
      </MDBox>
      <Dialog
        open={openDeleteBetLimit}
        onClose={handleCloseDeleteBetLimit}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{ width: '100%', textAlign: 'center' }}
        >{`Are you sure you want to delete this bet limit?`}</DialogTitle>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={() => {
              deleteBetLimit(id);
              handleCloseDeleteBetLimit();
            }}
          >
            Yes
          </MDButton>
          <MDButton
            variant='text'
            onClick={() => {
              handleCloseDeleteBetLimit();
            }}
          >
            No
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
