import { DateTimePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import { useEffect, useState, useMemo } from 'react';
import debounce from 'lodash.debounce';

export default function DateFilterComponent({ filters, setFilters }) {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [searchInput, setSearchInput] = useState(filters.search || ''); // Local state for search input

  const handleFromChange = (date, setterFrom, setterTo) => {
    if (to) {
      if (date > to) {
        setterFrom(to);
        setterTo(date);
      } else {
        setterFrom(date);
      }
    } else {
      setterFrom(new Date(date));
    }
    
    setFilters((filters) => ({
      ...filters,
      startDate: date
    }));
  };

  const handleToChange = (date, setterFrom, setterTo) => {
    if (from) {
      if (date < from) {
        setterFrom(date);
        setterTo(from);
      } else {
        setterTo(date);
      }
    } else {
      setterTo(new Date(date));
    }

    setFilters((filters) => ({
      ...filters,
      endDate: date
    }));
  };

  // Debounced search handler
  const debouncedSearch = useMemo(
    () => debounce((value) => {
      setFilters((filters) => ({
        ...filters,
        search: value,
      }));
    }, 1000), // 1000ms delay for search after last typed character
    [setFilters]
  );

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchInput(value); // Update local search input state immediately
    debouncedSearch(value); // Call the debounced function
  };

  useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      startDate: from || '',
      endDate: to || ''
    }));
  }, [from, to, setFilters]);

  return (
    <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginLeft: 'auto' }} mb={2}>
      <MDInput label='Search' onChange={handleSearch} value={searchInput} /> {/* Use local state */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label='From'
          ampmInClock={false}
          closeOnSelect={true}
          ampm={false}
          showDaysOutsideCurrentMonth
          value={from}
          viewRenderers={{
            hours: null,
            minutes: null,
            seconds: null
          }}
          onChange={(e) => {
            handleFromChange(e.$d, setFrom, setTo);
          }}
          slotProps={{
            layout: {
              sx: {
                [`.${pickersLayoutClasses.actionBar}`]: {
                  display: 'none'
                }
              }
            }
          }}
        />
        <DateTimePicker
          label='To'
          ampmInClock={false}
          closeOnSelect={true}
          ampm={false}
          showDaysOutsideCurrentMonth
          value={to}
          viewRenderers={{
            hours: null,
            minutes: null,
            seconds: null
          }}
          onChange={(e) => {
            handleToChange(e.$d, setFrom, setTo);
          }}
          slotProps={{
            layout: {
              sx: {
                [`.${pickersLayoutClasses.actionBar}`]: {
                  display: 'none'
                }
              }
            }
          }}
        />
      </LocalizationProvider>
    </MDBox>
  );
}
