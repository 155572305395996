import DataTablePage from 'components/DataTablePage';
import { Navigate } from 'react-router-dom';
import { Can } from 'context';
import { getMetrics } from 'services/metrics';
import metricColumnData from 'data/metricColumnData';
import DateFilterComponent from './DateFilterComponent';
import { useState } from 'react';

function Metrics() {
  const [filters, setFilters] = useState({ search: '', startDate: '', endDate: '' });

  return (
    <>
      <Can I='read' a='metric'>
        <DataTablePage
          title='Metrics'
          canSearch
          canFilter
          fetchData={getMetrics}
          queryKey='metrics'
          columnData={metricColumnData}
          object={'metric'}
          noActions={true}
          filtersComponent={<DateFilterComponent filters={filters} setFilters={setFilters} />}
          filters={filters}
        />
      </Can>
      <Can not I='read' a='metric'>
        <Navigate to='/dashboard' replace />
      </Can>
    </>
  );
}

export default Metrics;
