import { secondsToDhms } from 'helpers';
import useAxios from 'hooks/useAxios';

export const getSessions = async (limit = 20, page = 1, filters = '') => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/session', {
      params: {
        limit: limit,
        page: page
      }
    });
    return {
      data: unformattedData.data.data.map((x) => {
        return {
          ...x,
          gameMode: x.game.game,
          game: x.game.name,
          duration: x?.duration ? secondsToDhms(x.duration) : 'Ongoing',
          playerUsername: x.player.username,
          sessionDate: x.createdAt,
          betRange: x.betLimit,
          sessionCountry: x.country,
          playerNickname: x.player.nickname
        };
      }),
      meta: unformattedData.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};
