import useAxios from 'hooks/useAxios';

export const getClients = async (limit = 20, page = 1) => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/client', {
      params: { limit: limit, page: page }
    });

    return {
      data: unformattedData.data.data.map((user) => {
        return {
          ...user,
          whitelistedIps: user.whitelistedIps.map((ip) => ip.ip).join(', ')
        };
      }),
      meta: unformattedData.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const getAutocompleteClients = async (nickname) => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/client/autocomplete', {
      params: {
        search: nickname,
      }
    });

    const data = unformattedData.data.data.map((user) => {
      return {
        ...user,
        value: user?.id ? user.id : '-',
        label: user?.name ? user.name : '-'
      };
    });

    return data;
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const getClient = async (id) => {
  const api = useAxios();
  try {
    const data = await api.get(`/client/${id}`);

    const ips = data.data.whitelistedIps.map((ip) => {
      return ip.ip;
    })

    const res = {
      id: data?.data?.id ? data.data.id : '-',
      name: data?.data?.name ? data.data.name : '-',
      callbackUrl: data?.data?.callbackUrl ? data.data.callbackUrl : '-',
      whitelistedIps: ips ? ips : [],
    };

    return res;
  } catch (err) {
    console.log(err);
    return {
      data: {
        name: '',
        callbackUrl: '',
        whitelistedIps: []
      }
    };
  }
};

export const createClient = async (client) => {
  const api = useAxios();
  try {
    return await api.post('/client', {
      name: client.name,
      callbackUrl: client.callbackUrl
    });
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};
export const updateClient = async (id, name, callbackUrl) => {
  const api = useAxios();
  try {
    const params = {
      name: name,
      callbackUrl: callbackUrl,
    };
    return await api.patch(`/client/${id}`, params);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};

export const deleteClient = async (id) => {
  const api = useAxios();
  try {
    return await api.delete(`/client/${id}`);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};

export const clientUser = async (id) => {
  const api = useAxios();
  try {
    return await api.delete(`/client/${id}`);
  } catch (err) {
    console.log(err);
    return {
      data: {
        name: '',
        callbackUrl: ''
      }
    };
  }
};
