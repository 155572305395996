import DataTablePage from 'components/DataTablePage';
import MDButton from 'components/MDButton';
import { Navigate, useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import { Can } from 'context';
import { useState } from 'react';
import clientColumnData from 'data/clientColumnData';
import { deleteClient, getClients } from 'services/clients';

function ClientManagement() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [deleteClientId, setDeleteClientId] = useState('');
  const [filters, setFilters] = useState('');

  return (
    <>
      <Can I='read' a='client'>
        <DataTablePage
          title='Client Management'
          createButton={
            <Can I='create' a='client'>
              <MDButton variant='contained' color='info' onClick={() => navigate('/client-management/new-client')}>
                Add Client
              </MDButton>
            </Can>
          }
          canSearch
          canFilter
          fetchData={getClients}
          queryKey='clients'
          columnData={clientColumnData}
          object={'client'}
          onDelete={(id) => {
            setDeleteClientId(id);
            handleOpenModal();
          }}
          filters={filters}
        />
        <Dialog
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Are you sure you want to delete this client?'}</DialogTitle>
          <DialogActions>
            <MDButton
              onClick={async () => {
                await deleteClient(deleteClientId);
                handleCloseModal();
                setFilters(filters.length ? '' : 'd');
              }}
            >
              Yes
            </MDButton>
            <MDButton onClick={handleCloseModal}>No</MDButton>
          </DialogActions>
        </Dialog>
      </Can>
      <Can not I='read' a='client'>
        <Navigate to='/dashboard' replace />
      </Can>
    </>
  );
}

export default ClientManagement;
