import { AbilityBuilder, createMongoAbility } from '@casl/ability';
export const getUserAbilities = (role, client = null) => {
  const { can, build } = new AbilityBuilder(createMongoAbility);
  can('read', 'session');
  can('read', 'game');
  can('read', 'metric');
  can('manage', 'player');
  if (role == 'admin' && !client) {
    can('manage', 'all');
  } else if (role == 'user' && !client) {
    can('manage', 'client');
  }
  return build();
};
