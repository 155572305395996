/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from 'layouts/client-management/components/schemas/form';

const {
  formField: { name, callbackUrl, whitelistedIps }
} = checkout;
const initialValues = {
  [name.name]: '',
  [callbackUrl.name]: '',
  [whitelistedIps.name]: [],
};

export default initialValues;
