import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { NavLink } from 'react-router-dom';

const gamesColumnData = [
  {
    Header: 'Id',
    accessor: 'id'
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }) => {
      return (
        <>
          <MDBox sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <NavLink to={`/game/${row.original.id}`}>
              <MDTypography fontSize='0.875rem'>{row.original.name}</MDTypography>
            </NavLink>
          </MDBox>
        </>
      );
    }
  },
  {
    Header: 'Percent Take',
    accessor: 'percentTake'
  },
  {
    Header: 'Min Crash',
    accessor: 'minCrash'
  },
  {
    Header: 'Max Crash',
    accessor: 'maxCrash'
  },
  {
    Header: 'Min Players',
    accessor: 'minPlayers'
  },
  {
    Header: 'Max Players',
    accessor: 'maxPlayers'
  },
  {
    Header: '',
    accessor: 'details',
    Cell: ({ row }) => {
      return (
        <>
          <MDBox sx={{ display: 'flex', justifyContent: 'center' }}>
            <NavLink to={`/game/${row.original.id}`}>
              <MDButton fontSize='0.875rem'>View & Edit</MDButton>
            </NavLink>
          </MDBox>
        </>
      );
    }
  }
];
export default gamesColumnData;
