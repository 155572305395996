import DataTablePage from 'components/DataTablePage';
import { Navigate } from 'react-router-dom';
import { Can } from 'context';
import gamesColumnData from 'data/gameColumnData';
import { getGames } from 'services/games';

function Games() {
  return (
    <>
      <Can I='read' a='game'>
        <DataTablePage
          title='Games'
          canSearch
          canFilter
          fetchData={getGames}
          queryKey='games'
          columnData={gamesColumnData}
          object={'game'}
          noActions={true}
        />
      </Can>
      <Can not I='read' a='game'>
        <Navigate to='/dashboard' replace />
      </Can>
    </>
  );
}

export default Games;
